<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />

</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_dpp',
      headers: [
        [{
          label: 'Jenis Prasarana',
          dataKey: 'jenis_prasarana',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'Jumlah Unit',
          dataKey: 'jumlah_unit',
          order: 2,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Luas (m2)',
          dataKey: 'luas_m_2',
          order: 3,
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
        }, {
          label: 'Kepemilikan',
          attributes: { colspan: 2 },
        }, {
          label: 'Kondisi',
          attributes: { colspan: 2 },
        }, {
          label: 'Penggunaan (Jam/Minggu)',
          dataKey: 'penggunaan_jam_minggu',
          attributes: { rowspan: 2 },
          dataAttributes: { class: 'text-center' },
          order: 8,
        }],
        [{
          label: 'SD',
          dataKey: 'sd',
          order: 4,
          dataAttributes: { class: 'text-center' },
          render(data) {
            return data == 1 ? '✓' : ''
          }
        }, {
          label: 'SW',
          dataKey: 'sw',
          order: 5,
          dataAttributes: { class: 'text-center' },
          render(data) {
            return data == 1 ? '✓' : ''
          }
        }, {
          label: 'Terawat',
          dataKey: 'terawat',
          order: 6,
          dataAttributes: { class: 'text-center' },
          render(data) {
            return data == 1 ? '✓' : ''
          }
        }, {
          label: 'Tidak Terawat',
          dataKey: 'tidak_terawat',
          dataAttributes: { class: 'text-center' },
          order: 7,
          render(data) {
            return data == 1 ? '✓' : ''
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Jenis Prasarana',
          name: 'jenis_prasarana',
          dataKey: 'jenis_prasarana',
          type: 'textarea',
          rules: 'required',
        }, {
          label: 'Jumlah Unit',
          name: 'jumlah_unit',
          dataKey: 'jumlah_unit',
          type: 'number',
          
        }, {
          label: 'Luas (m2)',
          name: 'luas_m_2',
          dataKey(data) {
            return data.luas_m_2.replace(' m2', '')
          },
          type: 'textarea',
          
          placeholder: 'm2'
        }, {
          label: 'Kepemilikan',
          name: 'kepemilikan',
          dataKey(data) {
            return data.sd == 1 ? 'SD' : 'SW'
          },
          type: 'select',
          options: ['SD', 'SW'],
          defaultValue: 'SD',
          col: 6,
        }, {
          label: 'Kondisi',
          name: 'kondisi',
          dataKey(data) {
            return data.terawat == 1 ? 'Terawat' : 'Tidak terawat'
          },
          type: 'select',
          options: ['Terawat', 'Tidak terawat'],
          defaultValue: 'Terawat',
          col: 6,
        }, {
          label: 'Penggunaan (jam/minggu)',
          name: 'penggunaan_jam_minggu',
          dataKey(data) {
            return data.penggunaan_jam_minggu.replace(' jam/minggu', '')
          },
          type: 'number',
          
          placeholder: 'jam',
        }],
        customPayload(data) {
          return {
            ...data,
            sd: data.kepemilikan == 'SD' ? 1 : 0,
            sw: data.kepemilikan == 'SW' ? 1 : 0,
            terawat: data.kondisi == 'Terawat' ? 1 : 0,
            tidak_terawat: data.kondisi == 'Tidak terawat' ? 1 : 0,
          }
        },
      },
    }
  },
}
</script>
